<template>
  <div id="examenes">
    <MenuBase
      :tipo="'examenes'"
      :headers="headers"
      :items="$store.state.tabla"
      :botones="botones"
      :opciones="opciones"
      :alineaHeaders='alineaHeaders'
      :titulo="titulo"
      :tituloOpciones="tituloOpciones"
      :campoOpcion="campoOpcion"
      @clic-boton="clicBoton"
      @clic-fila="clicFila"
      :icono="'mdi-test-tube'"
      :verTabla="true"
      :loading="!$store.state.tabla.length"
    />
    <Examen :modo="modoExamen" :dialogE.sync="dialogExamen" :nexamen.sync="this.campoOpcion" :origen.sync="origen"/>
    <DialogOpciones
      :dialog.sync="dialogOpcionesOtros"
      :opciones.sync="opcionesOtros"
      :width="400"
      @clic-boton="clicBotonEx"
    />
    <BotonCopiar v-show="false"
      api_existe="examenes/existe"
      api_copiar="examenes/copiar"
      titulo="Copiar Examen"
      :dialog.sync="dialogCopiarExamen"
      :loading.sync="loading"
      label="Nombre examen copia"
      :nombre.sync="campoOpcion"
      :copia.sync="copiaExamen"
      modo="tabla"
    />
  </div>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import MenuBase from '@/components/menubase.vue'
import Examen from '@/views/examen.vue'
import DialogOpciones from '@/components/dialogopciones.vue'
import ApiCon from '@/logic/apicon.js'
import BotonCopiar from '@/components/botoncopiar.vue'

export default {
  mixins: [
    Globales
  ],
  components: {
    MenuBase,
    Examen,
    DialogOpciones,
    BotonCopiar
  },
  data: () => ({
    headers: [],
    botones: [],
    opciones: [],
    titulo: 'Exámenes',
    tituloOpciones: 'Opciones Exámen: ',
    campoOpcion: '',
    alineaHeaders: 'start',
    dialogExamen: false,
    modoExamen: 'NUEVO',
    opcionesOtros: [],
    dialogOpcionesOtros: false,
    origen: 'tabla',
    labelActivado: '',
    colorActivado: '',
    dialogCopiarExamen: false,
    copiaExamen: ''
  }),
  methods: {
    interfaz () {
      this.headers = [
        { text: 'NOMBRE', align: 'start', value: 'nombre' },
        { text: 'TIPO', align: 'start', value: 'examen_tipo' },
        { text: 'MÉTODO', align: 'start', value: 'metodo' },
        { text: 'MUESTRA', align: 'start', value: 'muestra' },
        { text: 'REACTIVO', align: 'start', value: 'reactivo' },
        { text: 'ESTADO', align: 'start', value: 'estado' }
      ]
      this.botones = [
        { id: 1, nombre: 'NUEVO EXAMEN', color: 'primary', accion: 'nuevoExamen' },
        { id: 2, nombre: 'COMPONENTES', color: 'primary', accion: 'opcionesOtros' }
      ]
      this.opcionesOtros = [
        { id: 1, nombre: 'TIPOS EXAMEN', color: 'primary', accion: 'tiposExamen' },
        { id: 2, nombre: 'MUESTRAS', color: 'primary', accion: 'muestras' },
        { id: 3, nombre: 'REACTIVOS', color: 'primary', accion: 'reactivos' },
        { id: 4, nombre: 'MÉTODOS', color: 'primary', accion: 'metodos' },
        { id: 5, nombre: 'MEDIDAS', color: 'primary', accion: 'medidas' }
      ]
    },
    clicFila (fila) {
      this.campoOpcion = fila.nombre
      this.$store.commit('cambiarID', fila.id)
      this.cambiarActivacion(fila.estado)
    },
    clicBotonEx (accion) {
      if (accion === 'tiposExamen') {
        this.$router.push('tiposexamen')
      } else if (accion === 'muestras') {
        this.$router.push('muestras')
      } else if (accion === 'reactivos') {
        this.$router.push('reactivos')
      } else if (accion === 'metodos') {
        this.$router.push('metodos')
      } else if (accion === 'medidas') {
        this.$router.push('medidas')
      }
    },
    clicBoton (accion) {
      if (accion === 'nuevoExamen') {
        this.modoExamen = 'NUEVO'
        this.dialogExamen = true
      } else if (accion === 'editarExamen') {
        this.modoExamen = 'EDITAR'
        this.dialogExamen = true
      } else if (accion === 'activarExamen') {
        this.estadoExamen()
      } else if (accion === 'opcionesOtros') {
        this.dialogOpcionesOtros = true
      } else if (accion === 'copiarExamen') {
        this.dialogCopiarExamen = true
      }
    },
    estadoExamen () {
      if (this.colorActivado === 'success') {
        if (confirm('¿Está seguro que desea activar este examen?')) {
          this.cambiarEstadoExamen('Activado')
        } else {}
      } else {
        if (confirm('¿Está seguro que desea desactivar este examen?')) {
          this.cambiarEstadoExamen('Desactivado')
        } else {}
      }
    },
    async cambiarEstadoExamen (estado) {
      this.loading = true
      const ins = {
        idexamen: this.$store.state.ids,
        estado: estado
      }
      const response = await ApiCon.post('examenes/cambiarestado', ins)
      if (response.status === 200) {
        alert('El estado del examen ha sido cambiado')
        this.cambiarActivacion(estado)
        this.cargarItems()
      } else { alert('error cambiarEstadoExamen') }
      this.loading = false
    },
    cambiarActivacion (estado) {
      if (estado === 'Activado') {
        this.labelActivado = 'DESACTIVAR EXAMEN'
        this.colorActivado = 'error'
      } else {
        this.labelActivado = 'ACTIVAR EXAMEN'
        this.colorActivado = 'success'
      }
      this.opciones = []
      this.opciones = [
        { id: 1, nombre: 'VER/EDITAR EXAMEN', color: 'primary', accion: 'editarExamen' },
        { id: 2, nombre: 'COPIAR', color: 'primary', accion: 'copiarExamen' },
        { id: 3, nombre: this.labelActivado, color: this.colorActivado, accion: 'activarExamen' }
      ]
    },
    async cargarItems () {
      this.cargarTabla('examenes/todo', 'cambiarTabla', 'cargarTablaExamenes')
    },
    cargarDatos () {
      if (this.compruebaLogin() === true) {
        if (this.returnPrilegio('examenes') === true) {
          this.interfaz()
          this.cargarItems()
        } else { this.$router.push('/menu') }
      }
    }
  },
  created () {
    this.haySesion()
    this.cargarDatos()
  },
  watch: {
    dialogCopiarExamen () {
      if (this.dialogCopiarExamen === false) {
        this.cargarItems()
      }
    }
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: cornflowerblue;
  }
</style>
